import React from "react";
import "./PrivacyPolicy.css";
import { useNavigate } from 'react-router-dom';

const PrivacyPolicy = () => {

  const navigate = useNavigate(); 
  const closePrivacy  = (e) => {
    navigate("/");
  };

  return (
    <div className="privacy-policy-container">
      <div className="privacy-policy-header">
        <h1>Privacy Policy</h1>
        <button className="close-button" onClick={closePrivacy}>
          X
        </button>
      </div>
      <div className="privacy-policy-content">
        <p><strong>Last Updated:</strong> November 23, 2024</p>
        <p><strong>Effective Date:</strong> November 23, 2024</p>
        <h2>1. Introduction</h2>
        <p>
          At Valet Hook, your privacy is important to us. This Privacy Policy
          explains how we collect, use, and share your personal information
          when you use our website, mobile application, and related services
          ("Service"). By accessing or using the Service, you consent to the
          collection and use of information in accordance with this policy.
        </p>
        <h2>2. Key Definitions</h2>
        <p>
          To help you understand this policy, here are some key terms:
          <ul>
            <li><strong>Account:</strong> A unique account created for you to access and use the Service.</li>
            <li><strong>Affiliate:</strong> A company or organization controlled by, or under common control with, Valet Hook.</li>
            <li><strong>Company/We/Us/Our:</strong> Valet Hook, located at 804, A6 Block, Ncc Cyber Urbania, Tellapur, Hyderabad, Telangana, India – 502032.</li>
            <li><strong>Personal Data:</strong> Information that can identify you personally, such as your name, email, or phone number.</li>
            <li><strong>Service:</strong> Refers to the Valet Hook app and website.</li>
            <li><strong>Usage Data:</strong> Automatically collected information when you use the Service (e.g., IP address, browser type, time spent on pages, etc.).</li>
          </ul>
        </p>
        <h2>3. Information We Collect</h2>
        <p><strong>Personal Data:</strong> When you use our Service, we may ask you to provide personal information that can be used to identify or contact you. This may include:</p>
        <ul>
          <li>Name (First and Last)</li>
          <li>Phone number</li>
          <li>Email Id</li>
        </ul>
        <p><strong>Usage Data:</strong> We automatically collect information about how you interact with the Service, such as:</p>
        <ul>
          <li>IP address</li>
          <li>Browser type and version</li>
          <li>Pages visited, timestamps, and duration of visit</li>
        </ul>
        <h2>4. How We Use Your Information</h2>
        <p>
          We use the data we collect for the following purposes:
          <ul>
            <li>Provide and improve our Service</li>
            <li>Account management</li>
            <li>Communicate with you</li>
            <li>Personalize content</li>
            <li>Legal compliance</li>
          </ul>
        </p>
        <h2>5. Sharing Your Data</h2>
        <p>We may share your information in the following circumstances:</p>
        <ul>
          <li>With Service Providers</li>
          <li>Business Transfers</li>
          <li>Legal Requirements</li>
          <li>With your consent</li>
        </ul>
        <h2>6. Data Retention</h2>
        <p>
          We retain your personal data only for as long as necessary to fulfill
          the purposes outlined in this Privacy Policy.
        </p>
        <h2>7. Security of Your Data</h2>
        <p>
        We prioritize the security of your personal data and take it very seriously. However, it is important to acknowledge that no method of data transmission over the internet or electronic storage is entirely foolproof. While we employ commercially accepted practices to safeguard your personal data, we cannot provide an absolute guarantee of its security.
        </p>
        <h2>8. Your Rights</h2>
        <p>
          As a user, you have the following rights:
          <ul>
            <li>Access</li>
            <li>Correction</li>
            <li>Deletion</li>
            <li>Opt-out</li>
          </ul>
        </p>
        <h2>9. Children’s Privacy</h2>
        <p>
          Our Service is not directed to children under the age of 13. We do
          not knowingly collect personal information from children.
        </p>
        <h2>10. Third-Party Links</h2>
        <p>
          Our Service may contain links to external websites that are not
          operated by us.
        </p>
        <h2>11. Changes to This Privacy Policy</h2>
        <p>
          We may update this Privacy Policy periodically to reflect changes in
          our practices or for other operational, legal, or regulatory reasons.
        </p>
        <h2>12. Contact Us</h2>
        <p>
          If you have any questions or concerns about this Privacy Policy,
          please contact us via:
          <ul>
            <li>Website Contact Form: <a href="https://valethook.com/#contact_details" target="_blank" rel="noopener noreferrer">https://valethook.com/#contact_details</a></li>
            <li>Email: support@valethook.com</li>
          </ul>
        </p>
      </div>
    </div>
  );
};

export default PrivacyPolicy;
